import * as React from 'react';

import { CDN_URL } from 'src/constants';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText, UitkHeading } from 'uitk-react-text';

const PartnerAccountInfo = () => {
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkHeading>What is a partner account?</UitkHeading>
      <UitkText>
        A partner account contains your business and billing details, and helps you manage properties and Users.
      </UitkText>
      <UitkFigure imageFit="contain" style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
        <UitkImage src={`${CDN_URL}/images/partner_account_info_beta.png`} />
      </UitkFigure>
      <UitkHeading>Properties</UitkHeading>
      <UitkText>
        After creating your partner account using the business credentials, you will be asked to add properties you want
        to advertise. These properties will be associated with this partner account.
      </UitkText>
      <UitkHeading>Payment profile</UitkHeading>
      <UitkText>This payment and billing profile will be used for all your invoices and transactions.</UitkText>
      <UitkHeading>Users</UitkHeading>
      <UitkText>
        Once you finish setting up a Campaign, in “Partner account” section you will find how to invite Users and manage
        their permissions.
      </UitkText>
    </UitkLayoutFlex>
  );
};

export default PartnerAccountInfo;
