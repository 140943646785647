import React from 'react';
import { SetStateAction } from 'react';

import { AdCopyReviewController } from '@controllers/admin/ad-copy-review-controller';
import { AuthController } from '@controllers/auth-controller';
import { controllers } from '@controllers/index';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import { INavMenuItem, NavItem } from '@layout/layout-content/side-nav-bar-item';
import { ConfigStore } from '@stores/config-store';
import { PARTNER_TYPE, UserStore } from '@stores/user-store';
import { hasControllerAccess } from '@utils/controller-utils';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { UitkList } from 'uitk-react-list';
import { UitkText } from 'uitk-react-text';

export const getNavList = (env: string, user: UserStore): Array<INavMenuItem> => {
  const menu: Array<INavMenuItem> = [];
  const { partnerRoles, partnerType, adminContext } = user;

  // TEMP to just return reporting for FSL users
  if (partnerType === PARTNER_TYPE.FLIGHT_SPONSORED_LISTING && !adminContext) {
    menu.push({
      titleKey: 'Reports',
      href: '/reporting',
      relatedPages: new RegExp('null'),
      icon: '',
    });
    menu.push({
      titleKey: 'Logout',
      href: '/logout',
      relatedPages: new RegExp('null'),
      icon: '',
    });

    return menu;
  }

  controllers.forEach((Controller) => {
    const controller = new Controller() as AuthController;
    if ('navIcon' in controller === false || !controller.navIcon) {
      return;
    }
    let relatedPages = new RegExp('null');

    if (controller instanceof AdCopyReviewController) {
      relatedPages = new RegExp('copyReview');
    }

    const menuItem = {
      titleKey: controller.pageTitle,
      href: controller.path,
      relatedPages: relatedPages,
      icon: controller.navIcon,
    };

    if (!hasControllerAccess(partnerRoles, controller.accessRoles)) {
      return;
    }

    if (adminContext && controller.isAdminView) {
      menu.push(menuItem);
    }

    if (!adminContext && controller.isPartnerView) {
      menu.push(menuItem);
    }
  });

  return menu;
};

export interface ISideNavBarProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<SetStateAction<boolean>>;
  config?: ConfigStore;
  user?: UserStore;
}

const SideNavBar: React.FC<ISideNavBarProps> = observer((props: ISideNavBarProps) => {
  const { isMobile } = useLayoutHelper();
  const { setNavOpen, navOpen } = props;
  const { user, config } = useStores();

  const copyrightYear = DateTime.now().year;

  function closeNavIfMobile() {
    if (isMobile) {
      setNavOpen(false);
    }
  }

  let sideNavBarClass = isMobile ? `side-nav-bar side-nav-bar-mobile` : `side-nav-bar`;
  if (navOpen) {
    sideNavBarClass += ' side-nav-open';
  }
  const navList = getNavList(config.appConfig.deployedEnv, user);

  return (
    <div data-testid="side-nav" className={sideNavBarClass}>
      <nav id="navigation-menu-items" className="navigation-menu-items">
        <UitkList bullet="no-bullet" data-control="accordion">
          {navList.map((item: INavMenuItem, index: number) => (
            <NavItem key={index} item={item} index={index} closeNavIfMobile={closeNavIfMobile} />
          ))}
        </UitkList>
        <footer id="side-nav-bar-footer" className="side-nav-bar-footer">
          <UitkText size={200} spacing="six">
            {/* <LocalizedText message="sideNavBar.copyrightText" data={[copyrightYear]} /> */}
            &#169; {copyrightYear}, Expedia, Inc. All rights reserved.
            <br />
            <br />
            <a href="https://legal.expediagroup.com/privacy/privacy-and-cookies-statements/partner-sites/consolidated-ad-portal/">
              {/* <LocalizedText message="sideNavBar.privacyStatementLinkText" /> */}
              Privacy Statement
            </a>{' '}
            &bull;{' '}
            <a href="https://legal.expediagroup.com/privacy/privacy-and-cookies-statements/partner-sites1/consolidated-ad-portal1/">
              {/* <LocalizedText message="sideNavBar.cookieStatementLinkText" /> */}
              Cookies Usage
            </a>
          </UitkText>
        </footer>
      </nav>
    </div>
  );
});

export { SideNavBar };
