import moment from 'moment/moment';

import { DateInput } from '../common/__generated__/api/types';

export const isObjectEmpty = (obj: Record<string, unknown>): boolean => {
  return Object.values(obj).every((x) => x === null || x === '');
};

export const formatDate = (date): DateInput => {
  const utcDate = moment(date).utc();
  return {
    day: Number(utcDate.format('DD')),
    month: Number(utcDate.format('MM')),
    year: Number(utcDate.format('YYYY')),
  };
};

export const formatReportingDate = (date): DateInput => {
  const utcDay = new Date().getUTCDate();
  const utcMonth = new Date().getUTCMonth();
  const utcYear = new Date().getUTCFullYear();
  const utcToday = new Date(utcYear, utcMonth, utcDay);
  let formattedDate = moment(date);
  if (formattedDate.isAfter(moment(utcToday))) {
    formattedDate = moment(utcToday);
  }
  return {
    day: Number(formattedDate.format('DD')),
    month: Number(formattedDate.format('MM')),
    year: Number(formattedDate.format('YYYY')),
  };
};
