import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';

const HowBudgetingWorks = () => {
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkText>
        Your daily budget is the maximum you set for your campaign. Once you run out of your budget for the day, the ads
        in this campaign will stop running until the next day. If you want to set up separate budgets for your ads, you
        just need to create a new campaign.
      </UitkText>
      <UitkText>
        We strive to display your ads to get the most out of your budget and maximize bookings for your property. The
        rate ay which your budget is spent depends on traveler traffic, searches, and competing ads. If search traffic
        for your market is low, you may end the day with leftover budget remaining.
      </UitkText>
    </UitkLayoutFlex>
  );
};

export default HowBudgetingWorks;
