import * as React from 'react';

import { CDN_URL } from 'src/constants';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText, UitkHeading } from 'uitk-react-text';

const HowCampaignsWork = () => {
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkText>
        To advertise on Expedia Group sites, you need three things: a <b>campaign</b>, an <b>ad group</b>, an{' '}
        <b>ad group </b> and an <b>ad</b>
      </UitkText>
      <UitkText>
        <b>Campaigns</b> give your advertising structure and allow you to filter or run performance reports across
        multiple ads. You can create as many campaigns as you like.
      </UitkText>
      <UitkText>
        Under each campaign, you need at least one <b>ad group</b>, which assigns targeting to all the ads below it. You
        can target to specific regions or all regions. You can create as many ad groups as you like.
      </UitkText>
      <UitkText>
        Under each ad group is your <b>ad</b>, which is what travelers see in search results. Putting ads under ad
        groups helps you create multiple ads with the same targeting. You can create up to 11 ads per ad group (one per
        language).
      </UitkText>
      <UitkText>
        <UitkHeading>Here&apos;s an example</UitkHeading>
        As part of their campaign (Summer 2025), this Partner wants to run four ads in two countries. They&apos;ll
        create two ads (English and Spanish) in one ad group (United States), and two ads (English and French) in
        another ad group (Canada). They can track the performance of the overall campaign, but can also determine how
        each ad group performs and how each ad performs in each of those ad groups.
      </UitkText>
      <UitkFigure>
        <UitkImage src={`${CDN_URL}/images/sample_campaign.png`} />
      </UitkFigure>
    </UitkLayoutFlex>
  );
};

export default HowCampaignsWork;
