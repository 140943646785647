//@typescript-eslint/no-explicit-any
import React from 'react';

import { useStores } from '@hooks/use-stores';
import { UitkButtonSize, UitkSecondaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { UitkLink } from 'uitk-react-link';
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from 'uitk-react-sheet';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { UitkHeading, UitkSubheading, UitkText } from 'uitk-react-text';

interface UserPopoverProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visible: boolean;
  toggleVisibility: () => void;
  openDialog: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerRef: React.RefObject<any>;
}

export const UserPopoverSheet: React.FC<UserPopoverProps> = ({
  user,
  visible,
  toggleVisibility,
  openDialog,
  triggerRef,
}) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const { config } = useStores();
  const userProfileLink =
    config.appConfig.deployedEnv === 'production'
      ? 'https://accounts.expediagroup.com/partner/account'
      : 'https://accountsexpediagroupcom.staging.exp-test.net/partner/account';

  const handleShowDetails = () => {
    setShowDetails((c) => !c);
  };

  const togglePopover = () => {
    setShowDetails(false);
    toggleVisibility();
  };

  return (
    <UitkLayoutFlex space="four">
      <UitkLayoutFlexItem className="top-nav-section">
        <UitkLayoutFlex justifyContent={'end'}>
          <UitkLayoutPosition type="relative" style={{ border: 'none' }}>
            <UitkTertiaryButton
              inverse
              size={UitkButtonSize.MEDIUM}
              style={{ border: visible ? '1px solid white' : 'none' }}
              onClick={togglePopover}
              domRef={triggerRef}
            >
              <UitkIcon name="account_circle" className="top-nav-section" />
              {`${user?.name}`}
            </UitkTertiaryButton>
            <UitkSheetTransition isVisible={visible}>
              <UitkSheet
                position={{ inline: 'trailing' }}
                width={280}
                isVisible={visible}
                type="popover"
                triggerRef={triggerRef}
                onDismiss={toggleVisibility}
              >
                <UitkSheetContent>
                  <UitkText spacing="two" weight={'bold'} size={400} className="initials-style">
                    <span onDoubleClick={handleShowDetails}>{`${user?.initials}`}</span>
                  </UitkText>
                  <UitkText spacing="two" weight={'medium'} size={400} align={'center'}>
                    {`Hi, ${user?.name}!`}
                  </UitkText>
                  <UitkText spacing="two" align={'center'}>
                    {`${user?.primaryEmail}`}
                  </UitkText>
                  {showDetails && (
                    <>
                      <UitkText spacing="two">Roles:</UitkText>
                      <UitkText>
                        <b>{user.partnerRoles}</b>
                      </UitkText>
                      <UitkText spacing="two">
                        Id:
                        <button onClick={() => navigator.clipboard.writeText(user.userId)}>
                          <UitkIcon name="copy_content" />
                        </button>
                      </UitkText>
                      <UitkText>
                        <b>{user.userId}</b>
                      </UitkText>
                      <UitkText spacing="two">Type:</UitkText>
                      <UitkText>
                        <b>{user.partnerType}</b>
                      </UitkText>
                    </>
                  )}
                  <UitkSecondaryButton
                    size={UitkButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '20px' }}
                    onClick={() => window.open(userProfileLink, '_blank', 'noopener,noreferrer')}
                  >
                    Manage your user profile
                  </UitkSecondaryButton>
                  <UitkSpacingHr />
                  <UitkSubheading>Partner account</UitkSubheading>
                  <UitkLayoutFlex space="two" className={'partner-account-style'}>
                    <UitkLayoutFlexItem alignSelf={'center'}>
                      <UitkIcon name="account_circle" />
                    </UitkLayoutFlexItem>
                    <UitkLayoutFlexItem>
                      <UitkLayoutFlex style={{ display: 'block' }}>
                        <UitkLayoutFlexItem>
                          <UitkHeading tag="h6" size={7}>
                            {`${user?.partnerName}`}
                          </UitkHeading>
                        </UitkLayoutFlexItem>
                        <UitkLayoutFlexItem>
                          {!user.adminContext && (
                            <UitkLink>
                              <a href="/view-and-manage" style={{ textDecoration: 'none' }}>
                                View and manage
                              </a>
                            </UitkLink>
                          )}
                        </UitkLayoutFlexItem>
                      </UitkLayoutFlex>
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>
                  <UitkSecondaryButton
                    size={UitkButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '2rem' }}
                    onClick={openDialog}
                    name="visibility"
                    domRef={triggerRef}
                  >
                    Switch partner account
                  </UitkSecondaryButton>
                  <UitkTertiaryButton
                    size={UitkButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '20px' }}
                  >
                    <a href="/logout" style={{ textDecoration: 'none' }}>
                      Sign out
                    </a>
                  </UitkTertiaryButton>
                </UitkSheetContent>
              </UitkSheet>
            </UitkSheetTransition>
          </UitkLayoutPosition>
        </UitkLayoutFlex>
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );
};
